import React, { Component } from 'react';
import Accent from '../Accent';
import LinkButton from './../LinkButton';
import { img } from '../../lib/brand';
import styles from './styles.module.less';

var blogFallBack1 = img('BlogFallBack1.jpg');
var blogFallBack2 = img('BlogFallBack2.jpg');
var blogFallBack3 = img('BlogFallBack3.jpg');

class BlogFiveBlock extends Component {
   renderBlogs = (blog, index) => {
      //get date
      let d = new Date(blog.date.toString());
      let date = d.toLocaleDateString('en-US', {
         month: 'long',
         day: 'numeric'
      });

      //parse image & html
      let img = '';

      // if there is an image url like the cms shows, use that
      if (blog.image) {
         img = blog.image;
      } else {
         //random int 1 to 3
         let n = Math.floor(Math.random() * 3);
         //fallback for feature img
         const fallBacks = [blogFallBack1, blogFallBack2, blogFallBack3];
         img = fallBacks[n];
      }

      //parse owner image
      let authorImg = blog.author.gallery.photo;
      let authorName = blog.author.profile.name;

      let hashtag = [];
      //hashtag
      if (blog.categories) {
         for (let j = 0; j < blog.categories.length; j++) {
            let tag = '#' + blog.categories[j].replace(/\s/g, '');
            hashtag.push(` ${tag}`);
         }
      }

      let authorIsBe = authorImg.includes('bayequity_circle_mark') || authorImg.includes('-circle-mark') ? true : false;

      return (
         <div className={styles.blogItem} key={blog.id}>
            <a href={blog.slug}>
               <div className={styles.photoURL} style={{ backgroundImage: `url('${img}')` }}>
                  <span className={styles.hashtag}>{hashtag}</span>
                  <img
                     data-blink-src={authorIsBe ? '' : authorImg}
                     src={authorIsBe ? authorImg : ''}
                     alt={authorName}
                     className={authorIsBe ? styles.icon : styles.photo}
                  />
               </div>
               <div className={styles.content}>
                  <div className={styles.date}>{date}</div>
                  <div className={styles.blogTitle}>{blog.title}</div>
               </div>
            </a>
         </div>
      );
   };
   render() {
      const { blogs, header } = this.props;
      let items = blogs;
      if (!blogs || blogs.length === 0) {
         return null;
      }
      return (
         <section className={styles.BlogFiveBlock}>
            <div className={styles.blogContainer}>
               <div className={styles.inner}>
                  <h1 className={styles.title}>{header}</h1>
                  <Accent align="left" />
                  <div className={styles.innerContainer}>
                     <div className={styles.blogPanelLarge}>{this.renderBlogs(items[0])}</div>
                     <div className={styles.blogPanel}>{items.slice(1, 5).map(this.renderBlogs)}</div>
                  </div>

                  <div className={styles.ViewAll}>
                     <LinkButton href="/bay-equity-news" label="View All" />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default BlogFiveBlock;
