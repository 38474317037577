import React, { Component } from 'react';
import { imgBrand, alt } from '../../lib/brand';
import Accent from '../Accent';
import styles from './styles.module.less';

var pdf = imgBrand('resourcePdf.png');
var doc = imgBrand('resourceDoc.png');
var ppt = imgBrand('resourcePpt.png');

class ResourcesDisplay extends Component {
   constructor(props) {
      super(props);
      this.resources = [
         {
            name: '30 Things That Can Affect Your Rate',
            imgLink: ppt,
            url: '/pdf/things-that-can-affect-rate.pdf',
            alt: alt('30 Things That Can Affect Your Rate - Home Loan Resources')
         },
         {
            name: 'First-time homeowner checklist',
            imgLink: doc,
            url: '/pdf/first-time-homeowner-checklist.pdf',
            alt: alt('First-time Homeowner Checklist - Home Loan Resources')
         },
         {
            name: 'Guide to Buy a Home',
            imgLink: pdf,
            url: '/pdf/guide-to-buy-a-home.pdf',
            alt: alt('Guide to Buying a Home - Home Loan Resources')
         }
      ];
   }

   render() {
      return (
         <div>
            {this.resources.map((s, i) => {
               return (
                  <div key={i}>
                     <a href={s.url} download target="_blank" rel="noopener noreferrer">
                        <div className={styles.resourceContainer}>
                           <img alt={s.alt} className={styles.resourceImage} src={s.imgLink} />
                           <div className={styles.resourceDescription}>{s.name || 'Download'}</div>
                        </div>
                     </a>
                  </div>
               );
            })}
         </div>
      );
   }
}

const ResourcesBe = () => {
   return (
      <section className={styles.container}>
         <div className={`${styles.inner} ${styles.helpModule}`}>
            <div className={styles.help}>
               <div className={styles.content}>
                  <div>
                     <h1 className={styles.title}>Need some help?</h1>
                  </div>
                  <Accent align="left" />
                  <div className={styles.copy}>
                     That’s why we’re here. Download a variety of helpful documents and resources to make your loan
                     process that much easier.
                  </div>
               </div>
               <div className={styles.resources}>
                  <ResourcesDisplay />
               </div>
            </div>
         </div>
      </section>
   );
};

export default ResourcesBe;
